.rootComponent {
  background-color: var(--background-color);
  padding: 20px 6% 30px 6%;
  display: flex;
  flex: 1;
  justify-content: center;
}

.innerComponent {
  display: flex;
  flex: 1;
  align-items: center;
}

.block_1_heading_component > h1 {
  font-size: 3vmax;
  text-align: center;
}


.block_1 {
  display: flex;
  flex: 0.5;
  height: 75%;
  justify-content: flex-end;
  margin-top: -4%;
  margin-right: 4%;
}

.innerComponent > div:last-child > img {
  width: 90%;
  height: 60%;
}

.block_2 {
  display: flex;
  justify-content: flex-end;
  flex: 0.5;
}

.andText {
  text-align: center;
  font:500 70px "Balsamiq Sans";
  padding: 30px 20px;
  margin-left: 3%;
}


.block_1_name_component {
  display: flex;
  align-items: flex-end;
  height: 50%;
  justify-content: flex-end;
}

.block_1_name_inner_component {
  display: flex;
  align-items: flex-end;
  flex: 1;
}

.block_1_name_inner_component {
  display: flex;
  align-items: flex-end;
  /* height: 100%; */
  /* margin-top: 25%; */
  justify-content: space-between;
}

.block_1_heading_component p:last-child {
  font-size: 30px;
  text-align: center;
}

.block_1_heading_component > h1 > span {
  color: #274585;
  font-size: 5vmax;
}

.contactButton {
  width: 7vmax;
  border-radius: 20px;
  padding: 0.5vmax;
  color: #ffffff;
  border-width: 0px;
  transition: all 0.5s;
  background-color: var(--navbar-bg-color);
  cursor: pointer;
  font: 500 0.9vmax "Balsamiq Sans";
  align-items: center;
  display: flex;
  justify-content: center;
}

.contactButton:hover {
  transform: scale(1.1);
  font: 500 1vmax "Balsamiq Sans";
}

.mobileComponent {
  position: relative;
}

.mobileImageComponent {
}
.mobileImageComponent > img {
  width: 100%;
}

.mobileHeadingComponent {
  z-index: 1;
}

.mobileHeadingComponent > h1 {
  font-size: 2vmax;
}

.mobileHeadingComponent > p {
  font-size: 2vmax;
}

.mobileHeadingComponent > h1 > span {
  color: var(--navbar-bg-color);
  font-size: 5vmax;
}

.mobileNameComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10%;
}
.mobileandText{
  align-items: flex-start;
  padding-left: 20%;
  
}

.mobileContactButton {
  background-color: var(--navbar-bg-color);
  padding: 1vmax 2.5vmax;
  border-width: 0px;
  font: 300 2vmax "Balsamiq Sans";
  border-radius: 10px;
  color: var(--white-color);
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  transition: all 0.5s;
}

.mobileContactButton:hover {
  transform: scale(1.1);
}
