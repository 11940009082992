.container {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    background: #d0dfea;
    display: flex;
    padding:50px;
  }
  .second_container {
    min-width: 20%;
    height: fit-content;
    width: fit-content;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
.from_container
{
    padding: 3rem;
    width:2rem ;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #274585, #d0dfea);
    align-items: center;
}
.create_listing
{
    padding: 0.5rem; 
    margin-top: 0.75rem; 
    text-align: center;  
    color: azure;
    background-color: #000;  
}
.inputcontainer
{
  display: flex;
  flex-direction: column;
  padding: 0.5rem; 
  margin-top: 0.75rem; 
}
.inputfild{
  
    border-radius: 5px;
    border: 1px solid #dddddd;
    outline: none;
    padding: 8px 15px;
    color: #000;
  }
  .inputfild:hover {
    border-color: #ccc;
  }
  
  .inputfild:focus {
    border-color: #274585;
  }

  .inputfild2{
    width: 100%;
    padding: 50px 50px;
    margin: 8px 0;
    height:70px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

  }