.root {
  width: 70%;
  margin: 3% auto;
  padding: 3px;
  display: flex;
  border: 1px solid #000;
  border-radius: 8px;
}

.root>img {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.mystoryright {

  background-color: #d0dfea;
  padding: 5%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 680px;
  overflow-y: scroll;
  scroll-behavior: auto;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d0dfea; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #274585; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2b2b2b; 
}
.mystoryright h1 {
  color: #dfdfdf;
  font-size: 20px;
}

.mystoryright>h1>span {
  font: 700 3vmax "Balsamiq Sans";
  color: #274585;
}

.mystoryright p {
  color: #2b2b2b;
  line-height: 1.5;
}

.underline {
  background-color: #ffffff;
  height: 2px;
  width: 10%;
}

.descriptionComponent {
  margin-top: 3%;
  margin-bottom: 2%;
  
}

.sign {
  font-size: 1.5vmax;
  font-family: font6, font6abc, cursive;
}

.name {
  font-size: 13px;
  margin-top: 5px;
}

.descriptionComponent>p>a {
  color: var(--navbar-title-bg);
  outline: none;
  text-decoration: none;
}

.descriptionComponent>a:hover {
  outline: none;
}

.imageComponent {
  width: 400px;
}

.imageComponent>img {
  width: 100%;
  height: 100%;
}

.mobileComponent {
  padding: 10px;
  margin: 2% auto;
}
.mobileInnerComponent {
  border-radius: 8px;
  overflow: hidden;
}

.mobileImageComponent {
  width: 100%;
  height: 250px;
}

.mobileImageComponent>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.linkedin {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 600px) {
  .root {
    width: 100%;
    flex-direction: column;
    padding: 0px;
    margin-top: 7%;
    border: 0;
  }

  .imageComponent {
    height: 400px;
  }

  .mystoryright {
    border-radius: 0;
  }

  .sign {
    text-align: right;
  }

  .root>img {
    width: 100%;
    border-radius: 0;
    height: 400px;
  }
}
