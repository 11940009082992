.component {
  background-color: var(--navbar-bg-color);
  padding: 2% 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onewon {
  text-decoration: none;
  color: #ffffff;
  outline: none;
}

.onewon:hover {
  outline: none;
  color: #ffffff;
}

.component > div:last-child > p >span {
  color : #E3F6F9
}

.component > div:first-child > p , .component > div:last-child > p {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
}

.component > div:last-child > p:first-child {
  text-align: left;
}

.verticalLine {
  display: none;
}
.monicaTelNo a{ 
    
  text-decoration: none;
  color: #ffffff;
}

@media screen and (max-width: 600px) { 
  .component {
    padding: 3% 5% 6% 3%;
  }
  .component > div:first-child > p , .component > div:last-child > p {
    color: #ffffff;
    text-align: center;
    padding: 5px;
    font-size: 13px;
  }
  .verticalLine {
    width: 2px;
    height: 20px;
    background-color: #fff;
    display: block;
  }
  .monicaTelNo a{
    
      text-decoration: none;
      color: #ffffff;
  }
  
}