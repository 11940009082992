
  


  #background {
    width: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  .component {
    padding: 10px;
    align-items: center;
    margin: auto;
    margin-top: 3%;
  }
  
  .introComponent {
    flex: 1 2 1px;
    align-self: flex-start;
  }
  
  .description {
    margin-top: 5%;
    padding: 0 10%;
  }
  
  .description > p {
    font-size: 1.1vmax;
    margin-top: 3%;
    line-height: 1.5;
  }

  .offers{
    align-itams:center;
    display:flex;
    justify-content: center;
    margin: 50px
  }
  .acontactButton{
     text-decoration: none;
  }
  .contactButton {
    width: 35vmax;
    border-radius: 10px;
    padding: 0.5vmax;
    color: #ffffff;
    border-width: 0px;
    transition: all 0.5s;
    background-color: #1f7ade;
    cursor: pointer;
    font: 500 2vmax "Balsamiq Sans";
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
  
  .underline {
    height: 3px;
    background-color: #26a4bb;
  }
  @media screen and (max-width: 600px) {
    .description > p {
      font-size: 2.1vmax;
      margin-top: 3%;
      line-height: 1.5;
    }
    .introComponent h2{
      font-size: 2.1vmax;
      margin-top: 3%;
      line-height: 1.5;
    }
  }
  .slider_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    background: #fff;
    flex-wrap: wrap;
    align-items: center;
    z-index:0;
  }
  
  
  @media screen and (max-width: 600px) {
    .slider_container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 20px;
      background: #fff;
      flex-wrap: wrap;
      align-items: center;
    }
  
  }
  
 