.bannerComponent {
  width: 100%;
  height: 30%;
}
.bannerComponent > img {
  width: 100%;
  height: 100%;
}

.aboutShopify {
  display: flex;
  padding: 10px;
  width: 70%;
  margin: 4% auto;
}

.aboutShopify > div:first-child {
  flex: 0.5;
  padding: 10px 20px 0 0;
}

.aboutShopify > div:first-child > p {
  line-height: 2;
  font-weight: 500;
}

.aboutShopify > div:last-child {
  width: 300px;
  height: 300px;
  flex: 0.5;
}

.aboutShopify > div:last-child > img {
  width: 100%;
  height: 100%;
}
.contactButton {
  padding: 10px 5%;
  border-radius: 20px;
  background-color: var(--navbar-title-bg);
  transition: all 0.5s;
  margin-top: 9%;
  color: #fff;
  font-size: 1vmax;
  outline: none;
  border: none;
}
.contactButton:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.descriptionComponent {
    width: 70%;
    margin: 3% auto;
}

.descriptionComponent > p, .descriptionComponent > dl > dd {
    line-height: 2;
}

.descriptionComponent > dl {
    margin-top:1%; 
    margin-left: 3%;
}

.underline {
    width: 10%;
    margin: 3% auto;
    height: 2px;
    background-color: var(--navbar-bg-color);
}

@media screen and (max-width: 700px) {
    .aboutShopify {
        display: block;
        padding: 10px;
        width: 100%;
        margin: 4% auto;
      }

      .contactButton {
        padding: 10px 8%;
        border-radius: 20px;
        background-color: var(--navbar-title-bg);
        transition: all 0.5s;
        margin: 3% auto;
        color: #fff;
        font-size: 2.5vmax;
        outline: none;
        display: flex;
        border: none;
      }

      .aboutShopify > div:last-child {
        width: 100%;
        height: 300px;
        flex: 0.5;
      }

      .descriptionComponent {
        width: 100%;
        margin: 3% auto;
        padding: 20px
    }
}