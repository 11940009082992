.container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(180deg,  #274585, #d0dfea);
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerBox {
  min-width: 30%;
  height: fit-content;
  width: fit-content;
  background: linear-gradient(180deg, #274585, #d0dfea);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.inputfild{
  border-radius: 5px;
  border: 1px solid #dddddd;
  outline: none;
  padding: 10px 15px;
  color: #000;
}
.inputfild:hover {
  border-color: #ccc;
}

.inputfild:focus {
  border-color: #274585;
}
.inputcontainer
{
  display: flex;
  flex-direction: column;
}
.footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer .error {
  font-weight: bold;
  font-size: 0.875rem;
  color: #ff3300;
  text-align: center;
}

.footer button {
  outline: none;
  border: none;
  background:  #274585;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 16px;
  width: 100%;
  transition: 100ms;
  cursor: pointer;
}

.footer button:disabled {
  background-color: gray !important;
}

.footer button:hover {
  background-color: #000;
}

.footer p {
  font-weight: 700;
  color: #000;
}

.footer p span a {
  font-weight: bold;
  color: #9900ff;
  letter-spacing: 1px;
  font-size: 1rem;
  text-decoration: none;
}
