.container {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  min-height: 50vh;
  z-index: 0;
}
.container_box {
   width: 60%;
  height: auto;
   padding: 4px;
  display: flex;
  border-radius: 4px;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
}
.contant_card{
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  
}
.contant_bx{
  width: 80%;
}

.imgbx {
  min-width: 400px;
  max-heigh: 400px;
  flex: 0 0 150px;
  border-radius: 20px;
  transition: 0.5s;
}

.imgbx img {
  max-width: 100%;
  min-height: 50vh;
  overflow: hidden;
}
.container .text {
  margin-left: 5px;
  margin-top:10px;
}
.text h1 {
  
 
  color: #000;
  word-wrap: break-word;
}
.text pre {
  white-space: pre-wrap;
  
  color: #000;
}
.text p {
  word-wrap: break-word;
  
}
@media screen and (max-width: 1124px) {
  .container {
     width: 70%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
      min-height: 50vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
  }
}
@media screen and (max-width: 800px) {
  .container {
    width: 94%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    min-height: 50vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
  }
}
