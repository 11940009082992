.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  background: #d0dfea;
  flex-wrap: wrap;
  align-items: center;
}
.container_cat {
  height: 100%;
  width: 100.5vh;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  /* background:#d0dfea; */
  background-color: #d2b55f;
}
.container .container_cat h1 {
  color: #d2b55f;
  background: #000;
  font-weight: 1000;
  font-size: 20px;
  width: 100%;
  margin-right: 20px;
  padding: 5px;
  text-decoration: none;
}
.no_underlin{
  text-decoration: none;
  
}
.all_blogs img{
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .container {
    height: 100%;
     width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #d0dfea;
     flex-wrap: wrap;
    align-items: center;
    z-index: -1;
  }
  .container_cat {
    height: 100%;
    width: 100%;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: #d2b55f;
  }
    
}
