.swiper-button-prev {
    padding: 10px 14px 10px 16px;
    border-radius: 100%;
    border: 3px solid #333;
    margin-left: 30px;
    background-color: #333;
    padding: 20px;
  
  }
  .swiper-button-next {
    /* background-color: red; */
    padding: 10px 14px 10px 16px;
    border-radius: 100%;
    border: 3px solid #333;
    background-color: #333;
    margin-right: 30px; 
    padding: 20px;
  
  }