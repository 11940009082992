* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Balsamiq Sans";
  
}

:root {
  --navbar-bg-color: #274585;
  --navbar-title-bg: #5980D4;
  --white-color: #ffffff;
  --text-color: #4D4D4D;
  --background-color:  #d0dfea;
}

body::-webkit-scrollbar{
  display: none;
}