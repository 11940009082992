.container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: container;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: #d0dfea;
}
.btn {
  width: 13vmax;
  border-radius: 15px;
  padding: 0.4vmax;
  color: #ffffff;
  border-width: 0px;
  transition: all 0.5s;
  background-color: #274585;
  cursor: pointer;
  font: 700 1.2vmax "Balsamiq Sans";
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
