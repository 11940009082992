.root {
  padding: 3% 0;
  background-color: var(--background-color);
}
.innerComponent {
  width: 70%;
  padding: 10px;
  margin: 0 auto;
}

.titleComponent > h1 > span {
  color: var(--navbar-bg-color);
}

.underline {
  height: 2px;
  background-color: var(--navbar-title-bg);
  width: 14%;
  margin-top: 5px;
}

.mapCardComponent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

.card_style {
  width: 15vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: var(--white-color);
  padding: 1% 0;
  border-radius: 10px;
  transition: all 0.5s;
}

.card_style:hover {
  background-color: var(--navbar-bg-color);
  cursor: pointer;
  transition-delay: 0.1s;
  transform: scale(1.1);
  color: var(--white-color);
  font: 600 1vmax "Balsamiq Sans";
}

.card_style > div {
  align-items: center;
}

.imageComponent {
  border-radius: 100%;
  overflow: hidden;
  width: 12.5vmax;
  height: 12.5vmax;
  border: 10px solid #f7f7f7;
}

.imageComponent > img {
  width: 100%;
  height: 100%;
}

.descriptionComponent {
  margin: 8% 0 5% 0;
}

.name {
  margin-bottom: 10px;
}

.descriptionComponent > p,
.name {
  text-align: center;
  font: 600 1vmax "Balsamiq Sans";
}

.buttonComponent {
    width: 100%;
    margin: 5% auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.readMore {
    padding: 1% 5%;
    background-color: var(--navbar-bg-color);
    transition: all 0.5s;
    border:0px;
    border-radius: 20px;
    color: var(--white-color);
    text-align: center;
    cursor: pointer
}

.readMore:hover {
    /* background-color: var(--navbar-title-bg); */
    transform: scale(1.1);
}

@media screen and (max-width: 600px) {
  .root {
    padding-bottom: 20px;
  }
  .mobileMapCardComponent {
    display: flex;
    /* flex-grow: 1; */
    overflow-x: scroll;
    /* padding: 0 20px 0 75%; */
    
    
  }

  .titleComponent {
    padding: 10px;
  }

  .innerComponent {
    width: 100%;
    padding: 0px;
  }

  .descriptionComponent > p,
  .name {
    text-align: center;
    font: 600 2vmax "Balsamiq Sans";
  }

  .underline {
    width: 50%
  }

  .imageComponent {
    border-radius: 100%;
    overflow: hidden;
    width: 150px;
    height: 150px;
    border: 10px solid #f7f7f7;
  }

  .readMore {
    padding: 1vmax 3vmax;
    font: 400 15px "Balsamiq Sans"
  }

  .card_style {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    background-color: var(--white-color);
    padding: 5% 5%;
    border-radius: 10px;
    transition: all 0.5s;
  }
}
