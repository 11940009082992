.container {
   
    background: #274585;
    padding: 2px;
    border-bottom: 1px solid var(--navbar-bg-color);
  }
  .container {
    display: flex;
    justify-content: right;
    align-items: left;
    position: relative;
   
  
  }
  .all_bolgs{
    text-decoration: none;
    color:#fff;
    margin:10px;
  }
  .profile{
    text-decoration: none;
    color:#fff;
    margin-left:10px;
    margin-right:100px;
  }