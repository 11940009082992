.container {
  height: 100%;
  width: 100%;
  /* background: linear-gradient(180deg, #274585, #d0dfea); */
  background: #d0dfea;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.inputcontainer {
  display: flex;
  flex-direction: column;
  margin: 5%;
}

.innerBox {
  min-width: 30%;
  height: fit-content;
  width: fit-content;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.inputfild {
  width: auto;
  border-radius: 5px;
  border: 1px solid #dddddd;
  outline: none;
  padding: 6px 15px;
  color: #000;
  word-wrap: break-word;
  
}
input[type=text] {
  word-wrap: break-word;
  word-break: break-all;
  
}

.inputfild:hover {
  border-color: #ccc;
}

.inputfild:focus {
  border-color: #274585;
}

.btn {
  width: 7vmax;
  border-radius: 15px;
  padding: 0.3vmax;
  color: #ffffff;
  border-width: 0px;
  transition: all 0.5s;
  background-color: tomato;
  cursor: pointer;
  font: 500 0.9vmax "Balsamiq Sans";
  align-items: center;
  justify-content: center;
}
.Create_btn {
  width: 13vmax;
  border-radius: 15px;
  padding: 0.4vmax;
  color: #ffffff;
  border-width: 0px;
  transition: all 0.5s;
  background-color: #274585;
  cursor: pointer;
  font: 700 1.2vmax "Balsamiq Sans";
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.logout {
  display: flex;
  justify-content: flex-end;
}

.blogs_list {
  display: contents;
  flex: content;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  color: #fff;
}

.listing_blog1 {
  display: block;
  flex: container;
  margin-top: 5rem;
}
.listing_blog h6 {
  font-size: 20px;
}
.listingblog{
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  
}
.typeWordsList{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1150px) {
  .container {
    height: 100%;
    width: 100%;
    /* background: linear-gradient(180deg, #274585, #d0dfea); */
    background: #d0dfea;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:3%;
  }
  .innerBox {
    min-width: 90%;
    height: fit-content;
    width: fit-content;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .listingblog{
    display: flex;
    justify-content: center;
    padding: 2px;
    flex-wrap: wrap;
    align-items: center;
    
  }
}