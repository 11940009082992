.show {
  margin: 0;
  padding: 0;
}
.bodys {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #d0dfea;
  /* background: linear-gradient(180deg, #274585, #d0dfea); */
}

.container {
  position: relative;
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-style: solid;
  border-width: 4px;
  border-color: #d2b55f;
  border-radius: 10px;
  min-height: 50vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}
.container .card {
  position: relative;
  max-width: 100%;
  min-width:100%;
  background: linear-gradient(200deg, #000, #d0dfea);
  padding: 5px;
  display: flex;
  width: 100%;
  border-radius: 6px;
  min-height: 50vh;
  z-index:0;
  
}

.container .card .imgbx {
  min-width: 400px;
  max-heigh: 400px;
  flex: 0 0 150px;
  border-radius: 20px;
  box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15 green, blue, alpha);
  transition: 0.5s;
  
  
}
.container .imgbx img {
  max-width: 100%;
  min-height: 50vh;
  border-radius: 10px;
  box-shadow: 20px 35px 80px rgba(0, 0, 0, 0.15 green, blue, alpha);
  border: solid;
  border-width: 0px 3px 2px 0px;
  border-color: #d2b55f;
  z-index: -1;
  overflow: hidden;
}
.container .content {
  margin-left: 13px;
}
.content h1 {
  padding: 30px;
  margin-right:5px;
  margin-left:15px;
  color: #fff;
  word-wrap: break-word;
}
.underline {
  background-color: #d2b55f;
  height: 2px;
  margin-left:5px;
  width: 100%;
  border-radius:20px;
}
.container .card .content pre {
  white-space: pre-wrap;
  margin-left:10px;
  color: #fff;
}
.container .card .content p {
  word-wrap: break-word;
}
@media screen and (max-width: 850px) {
  .container{
    
     margin:20px;
  }
  .container .card {
    flex-direction: column;
    max-width: 350px;
    padding: 1px;
    background: linear-gradient(80deg, #000, #d0dfea);
    z-index:0;
   
  }
  .container .card .imgbx {
    min-width: 100%;
    flex: 0 0 150px;
  }
  .container .imgbx img {
    max-width: 100%;
    min-height: 50vh;
    border-radius:1px;
    box-shadow: 20px 35px 80px rgba(0, 0, 0, 0.15 green, blue, alpha);
    border: solid;
    border-width: 0px 0px 2px 0px;
    border-color: #d2b55f;
    z-index: -1;
    overflow: hidden;
  }
  .underline {
    background-color: #d2b55f;
    height: 2px;
    margin-left:5px;
    width: 20%;
    border-radius:20px;
  }
  .container .card .content pre {
    white-space: pre-wrap;
    margin-left:10px;
    margin-bottom:30px;
    color: #fff;
  }
 
  
}
