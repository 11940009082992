
.maindiv p {
    line-height: 3;
    
    font-weight: 500;
}

.maindiv {
    padding: 50px 100px;
  }

.maindiv  .imageComponent {
    width: 100%;
  }

  .imageComponent > img {
    width: 100%;
    height: 100%;
  }


.headingComponent {
    padding: 3% 0;
    align-items: center;
    display: flex;
    justify-content: center;
}

.headingComponent > h1 {
    color: #5D5D5D;
}

.maindiv ul {
    margin-left: 5%;
}

.maindiv ul li {
    font-size: 14px;
    line-height: 3;
}

@media screen and (max-width: 700px) {
  .maindiv {
    padding: 30px 20px;
  }

  .headingComponent h1 {
    text-align: center;
    font-size:25px;
  }

  .maindiv ul li {
    font-size: 14px;
    line-height: 2;
}
.maindiv p {
  
  font-weight: 500;
  line-height: 2;
}

}