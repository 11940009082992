.container{
    display:flex ;
    padding: 0;
    margin: 0;
    background-color: #d0dfea;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.leftdiv{
    display:flex ;
    font-size: 30px!important;
    color: #0c1824 !important;
    font-size: 5vmax!important;
    line-height: 50px!important;
    font-weight: 700!important;
    width: 20%;
    justify-content: flex-end;
    
}
.leftdiv h2{
    justify-content: flex-end;
    padding: 4px;
}
.rightdiv{
    display:flex ;
    width: 80%;
    font-size: 30px!important;
    color: #274585 !important;
    font-size: 5vmax!important;
    line-height: 50px!important;
    font-weight: 700!important;
    

}