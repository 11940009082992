.videoComponent {
  width: 100vw;
  height: 40vmax;
}

#background {
  width: 100%;
  position: relative;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.aboutecommerce {
  display: flex;
  padding: 10px;
  width: 80%;
  margin: 4% auto;
}

.component {
  padding: 20px;
  margin-top: 2%;
}

.mobileComponent {
  padding: 1px;
  margin-top: 4%;
}

.underline {
  height: 3px;
  background-color: #26a4bb;
  margin-top: 5px;
}

.innerComponent {
  max-width: 100%;
  margin: auto;
}

.cardsComponent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
}

.mobileInnerComponent {
  width: 90%;
  margin: auto;
  overflow: hidden;
}

.cardComponent {
  width: 30%;
  padding: 10px;
  margin-bottom: 5%;
}

.mobileCardComponent {
  width: 100%;
}

.mobileCardComponent {
  padding: 5px;
  margin-top: 5%;
}

.redCircle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: #a916a6;
}

.description {
  margin-top: 3%;
}

.description > h2 {
  font-size: 25px;
  font-family: sans-serif;
}

.description > p {
  line-height: 1.5;
  margin-top: 1%;
}

.mobileDescription > h2 {
  font-size: 22px;
  font-family: sans-serif;
  margin-top: 4%;
}

.mobileDescription > p {
  line-height: 1.5;
  margin-top: 1%;
  font-size: 15px;
}

@media screen and (max-width: 700px) {
  .aboutecommerce {
    width: 100%;
  }

  .videoComponent {
    width: 40vmax;
  }

  #background {
    width: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}
