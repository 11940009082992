.container_lis {
  height: aoto;
  width: 50%;
  z-index: 0;
}

.container_box {
  width: 550px;
  height: auto;
  padding-bottom: 10px;
  margin: 20px 5px 25px 5px;
  background: #000;
  overflow: hidden;
  border-radius: 1px;
  transition: 0.5s;
}

.image img {
  width: 100%;
  height: 250px;
}

.btns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}

.btnbox1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 5px;
  
}
.btnbox2 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5px;
  
}

.btn {
  width: 7vmax;
  border-radius: 15px;
  padding: 0.3vmax;
  color: #ffffff;
  border-width: 0px;
  transition: all 0.5s;
  background-color: tomato;
  cursor: pointer;
  font: 500 0.9vmax "Balsamiq Sans";
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  text-decoration: none;
}

.textbox {
  text-decoration: none;
  color: #fff;
}

.text_box {
  margin-left: 10px;
}

.text_box h2 {
  margin: 5px 5px 8px 0px;
  color: #d2b55f;
  word-wrap: break-word;
}

.text_box p {
  word-wrap: break-word;
}
.container_typewords{
  padding: 10px;
}





@media screen and (max-width: 1290px) {
  .container_lis {
    height: aoto;
    width: 100%;
    margin-top: 10%;
  }
  .container_typewords{
    height: aoto;
    width: 100%;
    margin-top: 10%;
  }
  .container_box {
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    margin: 0px 5px 0px 5px;
    background: #000;
    overflow: hidden;
    border-radius: 1px;
    transition: 0.5s;
  }
}